import React, { useContext, useState } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { FormContext } from "../../../contexts/FormOnlyEmail.context";
import InputField from "../../Forms/FormFields/InputField";
import emailOnlyFormWithSelectStyles from "./EmailOnlyFormWithSelect.module.scss";
import { EmailOnlyFormProps } from "@types";
import BtnWithArrow from "../../Buttons/BtnWithArrow";

const EmailOnlyFormWithSelect: React.FC = () => {
  const {
    handleBlur,
    handleChange,
    email,
    placeholder,
    inputError,
    externalClassNames,
    hideInput,
    buttonText,
    buttonStyle,
    spinnerColor,
    isLoading,
    handleSubmit,
    hasError,
    typeSelected,
    setTypeSelected,
    typeError,
  }: EmailOnlyFormProps = useContext(FormContext);

  return (
    <form
      onSubmit={handleSubmit}
      className={`${emailOnlyFormWithSelectStyles.container} ${externalClassNames}`}
    >
      {!hideInput && (
        <InputField
          inputError={inputError}
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          type="text"
        />
      )}

      {hasError && (
        <small className={emailOnlyFormWithSelectStyles.errorMessage}>
          Your email is required.
        </small>
      )}

      <div className={emailOnlyFormWithSelectStyles.selectContainer}>
        <p className={emailOnlyFormWithSelectStyles.selectText}>
          What best describes you?
          <span className={emailOnlyFormWithSelectStyles.selectTextLight}>
            {" "}
            (Required)
          </span>
        </p>
        <RadioGroup.Root
          className={emailOnlyFormWithSelectStyles.radioContainer}
          value={typeSelected}
          required
          onValueChange={setTypeSelected}
        >
          <div
            className={`${emailOnlyFormWithSelectStyles.radioGroup} ${
              typeSelected === "practitioner"
                ? emailOnlyFormWithSelectStyles.radioGroupSelected
                : ""
            }`}
          >
            <RadioGroup.Item
              className={emailOnlyFormWithSelectStyles.radioItem}
              value="practitioner"
              id="r1"
            >
              <RadioGroup.Indicator
                className={emailOnlyFormWithSelectStyles.radioIndicator}
              />
            </RadioGroup.Item>
            <label
              className={`${emailOnlyFormWithSelectStyles.radioLabel} ${
                typeError && !typeSelected
                  ? emailOnlyFormWithSelectStyles.selectError
                  : ""
              }`}
              htmlFor="r1"
            >
              <span className={emailOnlyFormWithSelectStyles.radioLabelText}>
                I am a practitioner providing care.
              </span>
            </label>
          </div>
          <div
            className={`${emailOnlyFormWithSelectStyles.radioGroup} ${
              typeSelected === "client"
                ? emailOnlyFormWithSelectStyles.radioGroupSelected
                : ""
            }`}
          >
            <RadioGroup.Item
              className={emailOnlyFormWithSelectStyles.radioItem}
              value="client"
              id="r2"
            >
              <RadioGroup.Indicator
                className={emailOnlyFormWithSelectStyles.radioIndicator}
              />
            </RadioGroup.Item>
            <label
              className={`${emailOnlyFormWithSelectStyles.radioLabel} ${
                typeError && !typeSelected
                  ? emailOnlyFormWithSelectStyles.selectError
                  : ""
              }`}
              htmlFor="r2"
            >
              <span className={emailOnlyFormWithSelectStyles.radioLabelText}>
                I am seeking care for myself or another person.
              </span>
            </label>
          </div>
          {typeError && !typeSelected && (
            <small className={emailOnlyFormWithSelectStyles.errorMessage}>
              This is required. Please make a selection.
            </small>
          )}
        </RadioGroup.Root>
      </div>

      <BtnWithArrow
        classNames={emailOnlyFormWithSelectStyles.submitBtn}
        variant={buttonStyle}
        buttonText={buttonText}
        spinnerVariant={spinnerColor}
        loading={isLoading}
      />
    </form>
  );
};

export default EmailOnlyFormWithSelect;
