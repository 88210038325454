import { Cookie } from "@utils/Cookie";
import { segmentTrack } from "./segment-track";

type LeadCaptureType = {
  text?: string;
  url?: string;
  email?: string;
  resource?: string | null;
  typeSelected?: string | null;
};

export function leadCapture({
  text,
  url,
  email,
  resource,
  typeSelected,
}: LeadCaptureType): void {
  if (typeof window !== "undefined") {
    segmentTrack("Lead Captured", {
      lead_type: "email",
      initiator: "User",
      object: "Lead",
      action: "Captured",
      source_application: "Marketing",
      text,
      url: url || window.location.pathname,
      email,
      resource,
      type_selected: typeSelected,
      fbclid: sessionStorage.getItem("fbclid"),
      fcb: Cookie.getCookie("_fbc"),
      gclid: sessionStorage.getItem("gclid"),
      msclkid: sessionStorage.getItem("msclkid"),
      li_fat_id: sessionStorage.getItem("li_fat_id"),
      ttclid: sessionStorage.getItem("ttclid"),
      utm_source: sessionStorage.getItem("utm_source"),
      utm_medium: sessionStorage.getItem("utm_medium"),
      utm_campaign: sessionStorage.getItem("utm_campaign"),
    });
  }
}
