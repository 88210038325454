/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import GlobalQueryParams from "../GlobalQueryParams";
import SchemaScript from "../SchemaScript";
import LayoutSEOQuery from "../../assets/StaticQueries/LayoutSEO.query";
import ModalContainer from "../../containers/ModalContainer";
import PageScripts, { SEGMENT_KEY } from "../PageScripts";
import builder from "@builder.io/react";
import { StringParam, useQueryParam } from "use-query-params";
import { externalRedirect } from "@utils/externalRedirect";
import { Cookie } from "@utils/Cookie";
import CookieConsent from "@components/RedesignComponents/CookieConsent/CookieConsent";
import {
  segmentTrack,
  sendingPendingSegmentEvents,
} from "@utils/segment/segment-track";

builder.init(process.env.GATSBY_BUILDER_API_KEY!);

type DataProps = {
  props: any;
  element: any;
};

type ExternalDataType = {
  sourceUrl: string;
  targetUrl: string;
};

type DataGrailConsentEventType = {
  consentPreferences: {
    cookieOptions: { gtm_key: string; isEnabled: boolean }[];
  };
};

const PageElementWrapper: React.FC<DataProps> = ({ element, props }) => {
  const seo = LayoutSEOQuery();
  const [p] = useQueryParam("p", StringParam);

  useEffect(() => {
    (async function () {
      const redirectList = await externalRedirect();

      if (redirectList && redirectList.length > 0) {
        redirectList.forEach(item => {
          const redirect = item?.data?.redirect;

          const currentUrl = redirect?.find(
            (item: ExternalDataType) =>
              item?.sourceUrl === window.location.pathname
          );

          if (currentUrl) {
            const newUrl = p
              ? `${currentUrl?.targetUrl}${p}`
              : currentUrl?.targetUrl;

            return (window.location.href = newUrl);
          }
        });
      }
    })();
  }, []);

  const rebrandPage =
    props?.data?.allBuilderModels?.onePageFullNav?.content?.data?.rebrandPage;

  useEffect(() => {
    rebrandPage
      ? document.body.classList.add("sp-redesign")
      : document.body.classList.remove("sp-redesign");
  }, [rebrandPage]);

  useEffect(() => {
    if (p) {
      Cookie.setCookie("PROMOCODE", p, 365);
    }
    if (
      Boolean(
        JSON.parse(localStorage.getItem("visitedPricingPage") as string)?.value
      )
    ) {
      const expireDate = JSON.parse(
        localStorage.getItem("visitedPricingPage") as string
      ).expiresOn;
      if (Date.now() > expireDate) {
        localStorage.removeItem("visitedPricingPage");
      }
    }
  }, []);

  const options = {
    schemaReplacement: {
      from: "https://spgatsby.wpengine.com",
      to: "https://www.simplepractice.com",
    },
  };

  const preferencesCallback = (event: DataGrailConsentEventType) => {
    const data = {
      cookie_preferences: event?.consentPreferences?.cookieOptions,
      initiator: "User",
      object: "Privacy Choices",
      action: "Updated",
      url: window.location.pathname,
      source_application: "Marketing",
    };

    segmentTrack("Privacy Choices Updated", data);
  };

  useEffect(() => {
    window.dgEvent = window.dgEvent || [];
    window.dgEvent.push({
      event: "preference_callback",
      params: preferencesCallback,
    });

    document.addEventListener("dg:scriptinjected", (event: Event) => {
      const script = event?.detail?.script;

      if (!script) return;

      const consentId = script.getAttribute("data-consent-id");

      if (consentId === SEGMENT_KEY) {
        sendingPendingSegmentEvents();
      }
    });
  }, []);

  return (
    <SEOContext.Provider value={{ global: seo, options }}>
      <style id="dg-consent-custom-style">
        {`
          :host(.dg-consent-banner) * {
           font-family:  "Uncut Sans Variable";
          }

          :host(.dg-consent-banner) .dg-app {
            width: 400px;
          }

         .dg-header {
            padding: 24px 20px;
            font-size: 20px;
            font-weight: 600;
            line-height: 145%;
            letter-spacing: -0.4px;
            color: #123333;
          }

          .dg-main-content {
            overflow-y: auto;
          }

          :host(.dg-consent-banner) .dg-app {
            border: none;
            border-radius: 24px;
          }

          .dg-button {
            font-size: 16px;
          }

          .dg-button.essential_only, .dg-customization-button.dg-button {
            border-radius: 1000px;
            border: 1.5px solid #123333;
            color: #123333;
          }

          .dg-main-content-explanation {
            padding: 20px 20px 12px 20px;
            color: #123333;
            font-feature-settings: 'ss01' on, 'ss02' on, 'ss03' on, 'ss06' on, 'ss07' on;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 16.8px */
          }

          .dg-main-content-link-container {
            padding: 0px 20px 10px 20px;
          }

          .dg-main-content-link {
           color: #123333;
            font-feature-settings: 'ss01' on, 'ss02' on, 'ss03' on, 'ss06' on, 'ss07' on;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 165%;
            margin-bottom: 0px;
            padding-bottom: 10px;
          }

          .dg-essential strong {
            color: #123333;
            font-feature-settings: 'ss01' on, 'ss02' on, 'ss03' on, 'ss06' on, 'ss07' on;
            font-family: "Uncut Sans Variable";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }

          .dg-main-content-policies {
            padding: 0px 20px;
          }

          .dg-main-content-policy-option-heading {
            padding: 4px 0px 0px 0px;
          }

          ._48mA0q_buttonUnstyled {
            padding-left: 0px;
            padding-right: 0px;
          }

          .dg-slider.essential-label {
            font-size: 14px;
            font-weight: 400;
            line-height: 165%;
            color: #123333;
            font-feature-settings: 'ss01' on, 'ss02' on, 'ss03' on, 'ss06' on, 'ss07' on;
          }

          input[type=checkbox]:not(:disabled)+label .dg-slider {
            width: 40px;
            height: 14px;
            padding: 3.857px 0px;
            background: #DEE3E3;
          }

          input[type=checkbox]:not(:disabled):checked+label .dg-slider {
            background-color: #9BE8A8;
          }

          input[type="checkbox"]:not(:disabled) + label .dg-slider:after {
            width: 17.14px;
            height: 17.14px;
            top: 2px;
            left: 4px;
          }

          .dg-main-content-policy-option-heading .clickable strong {
            font-weight: 500;
            color: #123333;
            font-size: 16px;
            line-height: 150%;
          }

          .dg-main-content-policy-option-description {
           padding: 0px 0px 3px 8px;
          }

          .dg-main-content-policy-option-description p {
            font-size: 12px;
            font-weight: 400;
            color: #123333;
            font-feature-settings: "ss01" on, "ss02" on, "ss03" on, "ss06" on, "ss07" on;
            line-height: 140%;
            margin-top: 4px;
          }

          .dg-button.accept_all {
            height: 48px;
            padding: 16px 24px;
            border-radius: 1000px;
            background: #FFC700;
            border: none;
            color: #123333;
          }

          .dg-main-actions {
            padding: 20px 20px;
            gap: 12px;
          }

          @media(max-width: 480px) {
            :host(.dg-consent-banner) .dg-app {
              width: 100%;
            }

            .dg-consent-banner {
              height: max-content;
              width: 100%;
            }

            .dg-header {
              font-size: 18px;
            }

            .dg-main-content-explanation {
              font-size: 11px;
            }

            .dg-button.accept_all, .dg-button.essential_only, .dg-customization-button.dg-button {
              height: 44px;
            }
          }
        `}
      </style>
      <ModalContainer {...props}>
        <PageScripts />
        <SchemaScript
          jsonScript={props.pageContext?.jsonLdSchema?.schemaScript}
        />
        <GlobalQueryParams />
        {element}
      </ModalContainer>
      <CookieConsent />
    </SEOContext.Provider>
  );
};

export default PageElementWrapper;
