import React from "react";
import { EmailOnlyFormProps } from "../components/shared/EmailOnlyForm/Email.type";

export const formInitialCtx: EmailOnlyFormProps = {
  buttonStyle: "primary-red",
  withArrow: false,
  buttonText: "",
  email: "",
  handleBlur: (e: React.ChangeEvent<HTMLInputElement>) => {},
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {},
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => {},
  inputError: false,
  isLoading: false,
  placeholder: "",
  spinnerColor: "primary",
  externalClassNames: "",
  hideInput: false,
  hasError: false,
};

export const FormContext = React.createContext(formInitialCtx);
