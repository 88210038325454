import { segmentTrack } from "./segment-track";

type OptionalPropertiesType = {
  first_name?: string;
  last_name?: string;
  email?: string;
  specialty?: string;
  practice_size?: number;
};

export function modalBannerViewed(title: string, step?: string): void {
  if (typeof window !== "undefined") {
    segmentTrack("Marketing Modal/Banner Viewed", {
      title,
      url: window.location.pathname,
      step,
      initiator: "User",
      source_application: "Marketing",
      object: "Modal/Banner",
      action: "Viewed",
    });
  }
}

export function modalBannerDismissed(title: string): void {
  if (typeof window !== "undefined") {
    segmentTrack("Marketing Modal/Banner Dismissed", {
      title,
      url: window.location.pathname,
      initiator: "User",
      source_application: "Marketing",
      object: "Modal/Banner",
      action: "Dismissed",
    });
  }
}

export function modalBannerCompleted(
  title: string,
  text: string,
  optionalProperties?: OptionalPropertiesType
): void {
  if (typeof window !== "undefined") {
    segmentTrack("Marketing Modal/Banner Completed", {
      title,
      url: window.location.pathname,
      text,
      initiator: "User",
      source_application: "Marketing",
      object: "Modal/Banner",
      action: "Completed",
      ...(optionalProperties && { ...optionalProperties }),
    });
  }
}
