import React from "react";
import { ButtonVariantType, SpinnerVariantType } from "@types";

import Spinner from "../../../components/UI/Spinner";
import RightArrow from "../../SVGIcons/RightArrow/RightArrow";

import styles from "./BtnWithArrow.module.scss";

export type BtnWithSpinnerType = {
  buttonText: string;
  variant?: ButtonVariantType;
  classNames?: string;
  loading?: boolean;
  spinnerVariant?: SpinnerVariantType;
  [key: string]: any;
};

const BtnWithArrow = ({
  buttonText,
  variant,
  loading,
  spinnerVariant,
  classNames,
  ...rest
}: BtnWithSpinnerType) => {
  const variantOpts = {
    "primary-red": styles.primary__red,
    "primary-blue": styles.primary__blue,
    "primary-yellow": styles.primary__yellow,
  };

  const variantOpt = variantOpts[variant]
    ? variantOpts[variant]
    : variantOpts["primary-red"];

  const loadingStyle = loading ? styles.isLoading : "";

  return (
    <button
      data-testid="button:BtnWithArrow"
      {...rest}
      className={`${styles.btn__with_arrow} ${variantOpt} ${loadingStyle} ${classNames}`}
    >
      {loading ? (
        <Spinner variant={spinnerVariant} />
      ) : (
        <>
          {buttonText} <RightArrow />
        </>
      )}
    </button>
  );
};

export default BtnWithArrow;
