import React, { useState } from "react";

/* helpers */
import { setModalSeen } from "../../../utils/Cookie";
import { isValidEmail } from "../../../utils/validations";
import { setLocalStorageItem } from "../../../utils/LocalStorage";
import AlertMessage from "../AlertMessage/AlertMessage";
import {
  mxpEmailCaptured,
  mxpResourceDownload,
} from "../../../utils/mixpanelEvents/mixpanelEvents";
import EmailOnlyFormProvider from "../../../components/shared/EmailOnlyFormProvider";
import { EmailOnlyFormProps } from "../../../components/shared/EmailOnlyForm/Email.type";
import { formInitialCtx } from "../../../contexts/FormOnlyEmail.context";
import setRouteName from "../../../utils/getLocationName";
import { iterableSubmit } from "../../../utils/iterableSubmit";
import { IterableDataType } from "@types";
import generateUuId from "../../../utils/generateUuId";
import useFormSuccessBannerStore from "../../../sp-state/useFormSuccessBanner";
import useModalExitStore from "../../../sp-state/modals/useModalExitStore";
import useModalFormSuccessMsgStore from "../../../sp-state/modals/useModalFormSuccessMsgStore";
import useModalsTriggerStore from "../../../sp-state/modals/useModalsTriggerStore";
import useModalVisitorCookieStore from "../../../sp-state/modals/useModalVisitorCookieStore";
import { getFormattedTime } from "../../../assets/util-scripts/helpers";
import useGatedVideoStore from "../../../sp-state/useGatedVideoStore";

/* styles */
import styles from "./EmailCaptureForm.module.scss";
import { resourceDownload } from "@utils/segment/resourceDownload";
import { leadCapture } from "@utils/segment/leadCapture";
import { modalBannerCompleted } from "@utils/segment/ModalBanner";

type Props = {
  ctaId: string | null;
  ctaLink: string | null;
  ctaText: string;
  hideInput: boolean | null;
  visitorCookies: string;
  modalName: string;
};

const EmailCaptureForm: React.FC<Props> = ({
  hideInput,
  visitorCookies,
  ctaId,
  ctaText,
  modalName,
}) => {
  /* local state */
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { modalTrigger, resetModalTrigger } = useModalsTriggerStore(
    state => state
  );
  const { setFormSuccessBanner } = useFormSuccessBannerStore(state => state);
  const { resetModalVisitorCookie } = useModalVisitorCookieStore(
    state => state
  );
  const { modalExit, resetModalExit } = useModalExitStore(state => state);
  const { setModalFormSuccessMsg } = useModalFormSuccessMsgStore(
    state => state
  );

  const { locked, setLocked } = useGatedVideoStore(state => state);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const isPollenDigitalMagazine =
    modalTrigger === "pollen-dm-gated" ? true : false;
  const buttonStyle = isPollenDigitalMagazine
    ? "primary-yellow"
    : "primary-blue";
  const externalClassNames = !isPollenDigitalMagazine
    ? styles.defualtEmailCapture
    : styles.pollenDmEmailEmailCapture;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    window.analytics?.identify({
      account_status: "lead",
      email,
    });

    modalBannerCompleted(modalName, ctaText, {
      email,
    });

    const { pathname } = window.location || {};
    const [pathType, slug] = pathname.split("/").filter(value => value);
    const resource = pathType === "resource" ? slug : null;

    const analyticsData = {
      text: ctaText,
      email,
      resource,
    };

    leadCapture(analyticsData);

    try {
      setIsLoading(true);

      if (email.length === 0) {
        setHasError(true);
        return setErrorMessage("Your Email is required");
      }

      if (!isValidEmail(email)) {
        setHasError(true);
        return setErrorMessage("Your Email is an invalid format");
      }

      const signUpSource: { [key: string]: string } = {
        "pollen-lead": "Website - Get Pollen Weekly Modal",
        "resource-gated": "Website - Gated Resource",
        "pollinate-inbox": "Website - Homepage Pollen Modal",
        "sitewide-generic-resource": "Website - Join List Webinar Modal",
        "pollinate-inbox-inactive": "Internal - Pollen Modal",
        "pricing-redirect": "Internal - pricing modal",
        "sitewide-generic-gated": "Website - Gated Webinar Modal",
        "sitewide-generic": "Website - Sitewide Resources Modal",
        "pollen-dm-gated": "Internal - Pollen digital Magazine Gated Content",
      };

      const isGated =
        ctaId &&
        (ctaId === "resource-gated" ||
          ctaId === "sitewide-generic-gated" ||
          ctaId === "pollen-dm-gated");

      const formData: IterableDataType = {
        Digioh_Source: window.location.href,
        digioh_source: window.location.pathname.replace(
          /^(?:\/\/|[^/]+)*\//,
          ""
        ),
        email,
        eventName: "newUserSignup",
        SIGN_UP_SOURCE: ctaId && signUpSource[ctaId] ? signUpSource[ctaId] : "",
        ...(isGated && {
          gatedContent: {
            SUBMIT_URL: window.location.href,
            SUBMITTED_AT: getFormattedTime(),
          },
        }),
      };

      const { status } = await iterableSubmit(formData);

      if (status === 200) {
        setLocalStorageItem("gated_pass", "true");

        if (locked) {
          setLocked(false);
        }

        mxpEmailCaptured(email, ctaText);
        mxpResourceDownload(ctaText);
        if (isGated) resourceDownload(ctaText);

        const user_id = generateUuId();

        window.dataLayer.push({
          event: "lead_capture",
          location: `modal-${setRouteName()}`,
          email,
          user_id,
        });

        if (modalExit) {
          document.removeEventListener(
            "mouseleave",
            modalExit.handleMouseLeave
          );
          resetModalExit();
        }

        if (visitorCookies !== "sp_modal_seen") {
          setFormSuccessBanner(true);
          resetModalTrigger();
          setEmail("");
          setModalSeen(visitorCookies, resetModalVisitorCookie);
        } else {
          setModalFormSuccessMsg(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const ctx: EmailOnlyFormProps = {
    ...formInitialCtx,
    buttonStyle,
    buttonText: ctaText,
    email,
    handleChange,
    handleSubmit,
    withArrow: true,
    isLoading,
    placeholder: "Email",
    externalClassNames,
    hideInput,
  };

  return (
    <>
      <EmailOnlyFormProvider ctxInitialVal={ctx} />
      {hasError && (
        <AlertMessage
          data-testid="modal-form-input-error"
          message={errorMessage}
          onClose={() => setHasError(false)}
        />
      )}
    </>
  );
};

export default EmailCaptureForm;
