const addPendingSegmentEvents = (
  label: string,
  data: Record<string, unknown>,
  isPageView = false
) => {
  const allEvents = window.pendingSegmentEvents || [];

  allEvents.push({ label, data, isPageView });

  window.pendingSegmentEvents = allEvents;
};

export const sendingPendingSegmentEvents = () => {
  const allEvents = window.pendingSegmentEvents || [];

  if (allEvents?.length <= 0) return;

  for (const item of allEvents) {
    item.isPageView
      ? window.analytics.page(item.label, item.data)
      : window.analytics.track(item.label, item.data);
  }
};

export function segmentTrack(
  eventName: string,
  data: Record<string, unknown>,
  isPageView = false
) {
  try {
    if (!window?.analytics?.track) {
      return addPendingSegmentEvents(eventName, data, isPageView);
    }

    isPageView
      ? window.analytics.page(eventName, data)
      : window.analytics.track(eventName, data);
  } catch (error) {
    console.log("Segment Error", error); // eslint-disable-line no-console
  }
}
