import { segmentTrack } from "./segment-track";

export const resourceDownload = (
  text?: string,
  resource?: string,
  initiator?: string
) => {
  segmentTrack("Resource Downloaded", {
    text,
    resource,
    initiator: initiator || "User",
    object: "Resource",
    action: "Downloaded",
    url: window.location.pathname,
    source_application: "Marketing",
  });
};
