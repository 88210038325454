import React, { useState } from "react";

const CookieConsent: React.FC = () => {
  const checkCookie = localStorage.getItem("cookieConsentSP");
  const [visible, setVisible] = useState<boolean | null>(true);

  const closeNotice = () => {
    setVisible(false);

    // Remove from DOM after opacity transition
    setTimeout(() => {
      setVisible(null);
      localStorage.setItem("cookieConsentSP", "1");
    }, 701);
  };

  if (visible === null || checkCookie === "1") return null;

  // If no action is take after 10 seconds, hide banner
  setTimeout(() => {
    closeNotice();
  }, 10000);

  return (
    <div
      className={`cookie-consent fixed bottom-0 left-0 ${
        visible ? "opacity-100" : "opacity-0"
      } flex w-full max-w-[100vw] bg-forest-100 text-forest-900 p-sm body--text-regular visible transition-opacity duration-1000 z-[2147483637] motion-reduce:transition-none`}
      role="dialog"
      aria-label="Cookie Consent Banner"
    >
      <div className="cookie-consent__content md:max-h-none max-h-[30vh] w-full">
        <span className="break-words">
          This website utilizes technologies such as cookies to enable essential
          site functionality, as well as for analytics, personalization, and
          personalized advertising. To learn more, view the following link:{" "}
        </span>
        <a
          className="inline-block cursor-pointer text-forest-900 font-bold underline hover:no-underline"
          href="https://www.simplepractice.com/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </div>
      <button
        onClick={closeNotice}
        className="cookie-consent__btn self-start z-[2] cursor-pointer p-1 ml-sm relative -left-1"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          role="img"
          aria-labelledby="close-btn"
        >
          <g clipPath="url(#clip0_2133_370)">
            <title id="close-btn">Close this dialog</title>
            <path
              d="M2.35938 2.3291L13.361 13.3291"
              stroke="#123333"
              strokeWidth="2.1"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.7148 2.3291L2.71324 13.3291"
              stroke="#123333"
              strokeWidth="2.1"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2133_370">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  );
};

export default CookieConsent;
