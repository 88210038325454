import {
  extoleStart,
  googleTagManager,
  mixPanelScript,
  partnerStack,
  segmentScript,
} from "../../../node-utils/third-party-exports";
import { Script } from "gatsby";
import * as React from "react";

export const SEGMENT_KEY = process.env.GATSBY_DATAGRAIL_SEGMENT_ID;

const PageScripts: React.FunctionComponent = () => {
  return (
    <>
      <Script
        strategy={"idle"}
        dangerouslySetInnerHTML={{ __html: googleTagManager }}
      />
      <Script
        strategy={"idle"}
        dangerouslySetInnerHTML={{ __html: segmentScript }}
        type="text/plain"
        data-consent-id={SEGMENT_KEY}
      />
      <Script
        dangerouslySetInnerHTML={{ __html: mixPanelScript }}
        type="text/plain"
        data-consent-id={process.env.GATSBY_DATAGRAIL_MIXPANEL_ID}
      />
      <Script
        src="https://simplepractice.extole.io/core.js"
        type="text/plain"
        data-consent-id={process.env.GATSBY_DATAGRAIL_EXTOLE_ID}
      />
      <Script
        strategy={"idle"}
        dangerouslySetInnerHTML={{ __html: partnerStack }}
        type="text/plain"
        data-consent-id={process.env.GATSBY_DATAGRAIL_PARTNER_STACK}
      />
      <Script
        strategy={"idle"}
        dangerouslySetInnerHTML={{ __html: extoleStart }}
      />
    </>
  );
};

export default PageScripts;
