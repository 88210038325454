import { segmentTrack } from "./segment-track";

type MarketingCtaClickedType = {
  text: string;
  url: string;
  target?: string | null;
  emailIncluded?: string;
  planSelected?: string;
};

/**
 * ``Segment  Marketing CTA Clicked Event``
 *
 * ``Trigger:`` A Marketing CTA is clicked:.
 *
 * ``Properties:``
  - **text:** ``text of link being clicked``
  - **plan_selected:** ``(Optional) Represents user intent for a particular plan``
  - **email_included:** ``(Optional) flag for whether or not an email was submitted``
  - **initiator:** "User" ``also Client or Server to differentiate user initiated vs product``
  - **object:** "Market CTA",
  - **action:** "Clicked",
  - **url:** slug of the page user is on
  - **source_application:** "Marketing"
  - **target:** "page the user is navigating to"
 */

export function marketingCtaClicked({
  emailIncluded,
  planSelected,
  target,
  text,
  url,
}: MarketingCtaClickedType): void {
  if (typeof window !== "undefined" && window?.analytics?.track) {
    const urlStart = process.env.NODE_ENV === "development" ? "http" : "https";

    if (target?.startsWith(urlStart)) {
      const url = new URL(target);
      target = url?.pathname;
    }

    segmentTrack("Marketing CTA Clicked", {
      text,
      url,
      target: target || null,
      email_included: emailIncluded || "n/a",
      plan_selected: planSelected || "n/a",
      initiator: "User",
      action: "Clicked",
      object: "Market CTA",
      source_application: "Marketing",
    });
  }
}
