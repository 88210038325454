import React from "react";

import { EmailOnlyFormProps } from "../EmailOnlyForm/Email.type";
import { FormContext } from "../../../contexts/FormOnlyEmail.context";
import EmailOnlyForm from "../EmailOnlyForm";
import EmailOnlyFormWithDropdown from "../EmailOnlyFormWithDropdown";
import EmailOnlyFormWithSelect from "../EmailOnlyFormWithSelect";

type EmailOnlyFormProviderProps = {
  ctxInitialVal: EmailOnlyFormProps;
  shouldHaveExp?: boolean;
  withDropdown?: boolean;
  withSelect?: boolean;
  hasError?: boolean;
};

const EmailOnlyFormProvider: React.FC<EmailOnlyFormProviderProps> = ({
  ctxInitialVal,
  shouldHaveExp = true,
  withDropdown,
  withSelect,
  hasError,
}) => {
  const Form: React.FC = (() => {
    switch (true) {
      case withDropdown:
        return EmailOnlyFormWithDropdown;
      case withSelect:
        return EmailOnlyFormWithSelect;
      default:
        return EmailOnlyForm;
    }
  })();

  if (hasError) {
    ctxInitialVal.hasError = hasError;
  }

  return (
    <FormContext.Provider value={ctxInitialVal}>
      <Form />
    </FormContext.Provider>
  );
};

export default EmailOnlyFormProvider;
